import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Helmet } from "react-helmet"
import Seo from "../components/seo"
import Hero from "../components/hero"
import FeaturedArticle from "../components/featured-article"
import NewsGrid from "../components/news-grid"
import CtaSlider from "../components/cta-slider"

const NewsPage = () => {
  const { allStrapiArticle, strapiNews } = useStaticQuery(graphql`
    query {
      allStrapiArticle(sort: { order: DESC, fields: updatedAt }) {
        nodes {
          ...StrapiArticles
        }
      }
      strapiNews {
        hero {
          ...StrapiHero
        }
        seo {
          ...StrapiSeo
        }
        featuredArticle {
          ...StrapiFeaturedArticle
        }
        headings {
          ...StrapiHeading
        }
        callToAction {
          ...StrapiCallToAction
        }
        newsAdvertOverlayContent {
          news_advert_overlays {
            title
            heading
            description
            buttonName
            link
            isInternal
            image {
              alternativeText
              localFile {
                url
                childImageSharp {
                  gatsbyImageData(
                    formats: AUTO
                    layout: CONSTRAINED
                    placeholder: BLURRED
                    aspectRatio: 1.1
                  )
                }
              }
            }
          }
        }
      }
    }
  `)

  const {
    hero,
    seo,
    newsAdvertOverlayContent,
    headings,
    featuredArticle,
    callToAction,
  } = strapiNews

  const articles = allStrapiArticle.nodes

  const callToActionSections = callToAction.call_to_action_sections

  const newsAdverts = newsAdvertOverlayContent.news_advert_overlays

  const structuredData = seo.structuredData

  return (
    <>
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "website",
            structuredData,
          })}
        </script>
      </Helmet>

      <Seo
        title={seo.metaTitle}
        description={seo.metaDescription}
        image={seo.metaImage.localFile.publicURL}
        post={false}
        metaSocial={seo.metaSocial}
        metaRobots={seo.metaRobots}
      />

      <Hero hero={hero} />

      <FeaturedArticle
        featuredArticle={featuredArticle}
        title={headings.title}
        description={headings.description}
        bg={headings.bg}
      />

      <NewsGrid articles={articles} newsAdverts={newsAdverts} />

      <CtaSlider
        title={callToAction.title}
        description={callToAction.description}
        callToActionSections={callToActionSections}
        bg={callToAction.bg}
        image={callToAction.image}
        paypalButton={callToAction.paypalButton}
      />
    </>
  )
}

export default NewsPage
